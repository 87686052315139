<script lang="ts" setup>
const props = defineProps({
  lg: Boolean,

  tooltip: {
    type: String,
    default: ''
  }
})

const sizeClasses = computed(() => {
  if (props.lg) {
    return 'w-4 h-4 min-w-4'
  }

  return 'w-3 h-3 min-w-3'
})

type Theme = 'light' | 'dark'
const LOCAL_STORAGE_THEME_KEY = 'theme'
const darkMode = useState('theme', () => false)
const setTheme = (newTheme: Theme) => {
  localStorage.setItem(LOCAL_STORAGE_THEME_KEY, newTheme)
  darkMode.value = newTheme === 'dark'
}

watch(darkMode, (selected) => {
  setTheme(selected ? 'dark' : 'light')
})

</script>

<template>
  <BaseHoverMenu popper-class="tooltip">
    <template #default>
      <slot>
        <BaseIcon
          name="circle-info"
          class="textmode-neutral-500"
          :class="[sizeClasses]"
        />
      </slot>
    </template>

    <template #content>
      <div class="rounded-lg px-4 py-2" :class="[{ 'bg-gray-900 text-gray-100 tooltip-border-dark': darkMode }, { 'bg-gray-50 text-gray-800 tooltip-border-light': !darkMode }]">
      {{ tooltip }}
      </div>
    </template>
  </BaseHoverMenu>
</template>

<style>
.tooltip .v-popper__inner {
  @apply max-w-xs text-xs rounded-lg shadow-none;
}
.tooltip-border-light {
  border: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,0.1);
  box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.05);
}
.tooltip-border-dark {
  border: solid;
  border-width: 1px;
  border-color: rgba(255,255,255,0.07);
  box-shadow: 0px 2px 5px 2px rgba(255,255,255,0.05);
}

.tooltip .v-popper__arrow-outer,
.tooltip .v-popper__arrow-inner {
  @apply border-gray-900;
}
</style>
